jQuery(function () {
  const widgetUrl = process.env.WIDGET_URL

  $('.share').on('click', function (event) {
    event.preventDefault()
    window.open(
      $(this).attr('href'),
      'fbShareWindow',
      'height=450, width=550, top=' +
      ($(window).height() / 2 - 275) +
      ', left=' +
      ($(window).width() / 2 - 225) +
      ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0'
    )
  })
  $('#frame-text').on('copy', function () {
    return ga('send', 'event', 'copycode', 'manual', '1')
  })
  $('.nav-link').on('click', function (event) {
    ga(
      'send',
      'pageview',
      '/' +
      $(this)
        .attr('href')
        .substr(1)
    )
  })
  $('.event').on('click', function () {
    var $this, category, action, label
    $this = $(this)
    category = $this.data('category') || 'link'
    action = $this.data('action')
    label = $this.data('label')
    ga('send', 'event', category, action, label)
  })

  $('a[href*=#]:not([href=#])').on('click', function (event) {
    if (
      location.pathname.replace(/^\//, '') ==
      this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      var $el = $(this.hash)
      if (
        ($el = $el.length
          ? $el
          : $('[name=' + this.hash.slice(1) + ']'))
      ) {
        event.preventDefault()
        $('html,body').animate(
          {
            scrollTop: $el.offset().top,
          },
          1000
        )
      }
    }
  })

  new Clipboard('#copy-button')

  var selector = new Vue({
    el: '#logo-selector',
    data: {
      current: 1,
      sri: false,
      sriHash: null,
      sriVersion: null,
      widgetUrl: widgetUrl,
      logos: [
        { id: 1, img: 'img/shielded-button.svg', msg: 'button' },
        { id: 2, img: 'img/large-logo.png', msg: 'large tab' },
        { id: 3, img: 'img/small-logo.png', msg: 'small tab' },
      ],
    },
    computed: {
      sriLabel() {
        return this.sri
          ? ` integrity="${this.sriHash}"`
          : ''
      },
      versionTag() {
        return this.sri ? `-${this.sriVersion}` : ''
      },
    },
    methods: {
      setCurrent: function (current) {
        return (this.current = current)
      },
      setClass: function () {
        switch (this.current) {
          case 1:
            return 'left'
          case 2:
            return 'center'
          case 3:
            return 'right'
        }
      },
    },
    created() {
      fetch(`${this.widgetUrl}/integrity.json`)
        .then(response => response.json())
        .then(json => {
          if (json) {
            this.sriHash = json['integrity']
            this.sriVersion = json['version']
          }
        })
    },
  })

  var copy = new Vue({
    el: '#copy-holder',
    data: {
      current: selector.current,
    },
  })
})
